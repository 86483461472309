.swal-modal {
  background-color: #2f2f2f
}

.swal-modal .field {
  text-align: initial
}

.swal-modal.is-expanded {
  width: auto;
  max-width: 90%
}

.swal-title,
.swal-text {
  color: #eff0f1
}

.swal-text {
  text-align: center
}

.swal-content .label,
.swal-content .checkbox,
.swal-content .radio {
  color: #eff0f1
}

.swal-content .checkbox:hover,
.swal-content .radio:hover {
  color: #bdc3c7
}

.swal-button {
  background-color: #3794d2;
  color: #eff0f1
}

.swal-button:hover {
  background-color: #1496ed
}

.swal-button:focus {
  box-shadow: 0 0 0 1px #030303, 0 0 0 3px rgba(55, 148, 210, 0.29)
}

.swal-button--loading {
  color: transparent
}

.swal-button--danger {
  background-color: #da4453
}

.swal-icon--info {
  border-color: #3794d2
}

.swal-icon--info::after,
.swal-icon--info::before {
  background-color: #3794d2
}

.swal-icon--error {
  border-color: #da4453
}

.swal-icon--error__line {
  background-color: #da4453
}

.swal-icon--warning {
  border-color: #f67400;
  animation: pulseWarning 0.5s infinite alternate
}

.swal-icon--warning__body,
.swal-icon--warning__dot {
  background-color: #f67400;
  animation: pulseWarningBody 0.5s infinite alternate
}

@-webkit-keyframes pulseWarning {
  0% {
    border-color: #ffaa60
  }

  to {
    border-color: #f67400
  }
}

@keyframes pulseWarning {
  0% {
    border-color: #ffaa60
  }

  to {
    border-color: #f67400
  }
}

@-webkit-keyframes pulseWarningBody {
  0% {
    background-color: #ffaa60
  }

  to {
    background-color: #f67400
  }
}

@keyframes pulseWarningBody {
  0% {
    background-color: #ffaa60
  }

  to {
    background-color: #f67400
  }
}

.swal-icon--success {
  border-color: #27ae60
}

.swal-icon--success__line {
  background-color: #27ae60
}

.swal-icon--success__hide-corners {
  background-color: #2f2f2f
}

.swal-icon--success::after,
.swal-icon--success::before {
  background: #2f2f2f
}

.swal-display-thumb-container {
  min-width: 200px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center
}
